<template>

  <div class="flex flex-col items-center w-full">

    <div class="flex flex-wrap text-sm p-2 mt-4">

      <div
        v-for="(data,index) in pageData"
        :key="index"
        class="p-2 mx-1 transition-all duration-200 ease-in-out uppercase"
        :class="chosenType == index ? 'bg-pt-gray text-white' : 'hover:bg-pt-gray hover:text-white cursor-pointer'"
        @click="chooseType(index)"
      >
        {{ data.title }}
      </div>

    </div>

    <div class="flex gap-5 overflow-hidden">
      <div class="w-full lg:w-6/12">
        <img class="animate__animated animate__backInDown animate__fast" :key="chosenType" :src="pageData[chosenType].image_1" alt="">
      </div>
      <div class="w-full lg:w-6/12">
        <img class="animate__animated animate__backInDown animate__fast" :key="chosenType" :src="pageData[chosenType].image_2" alt="">
      </div>
    </div>

    <div class="flex max-w-screen-xl p-8 overflow-hidden">
      <div class="animate__animated animate__backInUp animate__fast" :key="chosenType">
        {{ pageData[chosenType].text }}
      </div>
    </div>

  </div>

</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {
      chosenType: 0,
      pageData: [
        {
          "type": "architectural",
          "title": "Architectural",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/architectural_1.jpg",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/architectural_2.jpg",
          "text": "Printotype's ability to work from conceptual data and 2D floor plan allows us to deliver to clients precise, scaled architectural models of their projects that can be of great help during the design, planning and sales pitch of a project. Giving users a direct hands-­on, tactile interaction with a particular idea lets you truly grasp the scale, dimensions, and final appearance of any given job."
        },
        {
          "type": "decorative",
          "title": "Decorative",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/decorative_1.jpg",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/decorative_2.jpg",
          "text": "3D Printing allows designers and creators to come up with some truly unique and elaborate designs. a 3D Printed piece can often be both functional and aesthetically pleasing, as well as purely decorative in their own right."
        },
        {
          "type": "engineering",
          "title": "Engineering",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/engineering_1.jpg",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/engineering_2.jpg",
          "text": "​​ It was originally the engineering world that developed 3d printing technologies, and this sector now more than ever is embracing the use of 3d printing in a multitude of different ways. A 3D printed component can often be an ideal \"proof of concept\" demonstration, be used to check measurement and fit tolerances, and even be used in the production of bespoke jig fixtures, either tailored to specific products or individual employees."
        },
        {
          "type": "medical",
          "title": "Medical",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/medical_1.jpg",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/medical_2.jpg",
          "text": "Alongside the Engineering sector, the medical sector has been at the forefront of embracing 3D printing to help provide better levels of service. This comes in many forms. 3D Printing offers the ability to produce competitively priced, anatomically correct models used for education and training. Alongside this, 3D Scanning and CT Data can be used to produce bespoke models from client data for the best possible diagnosis. and when it comes to actually treating medical conditions, 3d printing can be used to create braces, joint replacements, and even to print replacements for large sections of lost bone structure. Whilst the latter two are out of the scope of Printotypes current plans, we are developing a range of lightweight and comfort optimised sleeping and leisure braces for the paraplegic and tetraplegic community."
        },
        {
          "type": "product",
          "title": "Product Design",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/500x500.png",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/500x500.png",
          "text": "There are a great many small companies and individuals, within the local area and the uk in general that have product concepts or would like to develop an existing design further, but don't have access to either the resources, skillsets or hardware to act on these ambitions. We can take CAD and 3D data and help you refine these designs before the need to engage more costly and dedicated design & manufacture facilities."
        },
        {
          "type": "cad",
          "title": "Project Work & CAD",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/project_1.jpg",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/project_2.jpg",
          "text": "Have an upcoming project that you would like us to work step by step with you on? We can do that. We can take any initial sketches or design concepts through to a physical model via CAD and 3D Modelling software. This can include parametric based designs (i.e Solidworks, Inventor, Catia). We can also produce High quality 3D Shots of your product. Help visualise concepts and make adjustments before any physical work needs to begin, or help secure a client with an accurate depiction of the finished product. We can produce 3D walkarounds, flythroughs, cutaways and exploded views, As well as liaise with your own staff and specialists to ensure the project is flowing smoothly"
        },
        {
          "type": "consult",
          "title": "Consultancy Services",
          "image_1": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/consultancy_1.jpg",
          "image_2": "https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/consultancy_2.jpg",
          "text": "Developing a product, or potentially looking into rapid prototyping, can be confusing process. with one to one meetings and workshops, we can ascertain what your looking to do and the best way to achieve it. Partnerships with larger more established fabrication workshops allows us to use an array of equipment, not limited to; 5 Axis CNC Machining, SLS Printing (up to 600x600x400), as well as moulding and casting, Vacuum Forming, Sheet Metal Forming and Painting.We can meet with you to discuss a concept, take supporting information, and begin to produce a series of designs from specifications or CFIs."
        }
      ]
    }
  },
  components: {

  },
  methods: {
    chooseType(index) {
      this.chosenType = index;
    }
  }
}
</script>